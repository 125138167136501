.Cover {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, #ff2d2d, #61caff);
  display: flex;
}

.Cover .Bottom {
  width: 100%;
  padding-bottom: 1rem;
  height: fit-content;
  position: relative;
  bottom: 0;
  align-self: flex-end;

  display: flex;
  flex-direction: row;
  justify-content: center;
}