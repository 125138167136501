.App {
  overflow: auto;
}

.active {
  height: fit-content;
  width: 100%;
  position: fixed;
  top: 0px;
  transition: 0.1s linear;
  z-index: 40;
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
}

.hidden {
  height: fit-content;
  width: 100%;
  z-index: 40;
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
  position: fixed;
  top: -80px;
  transition: 0.3s linear;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #e1a85d;
}