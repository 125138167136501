.StartArrow {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;

  font-size: 1rem;
  transition: font-size 0.1s;
}

.StartArrow:hover {
  font-size: 1.2rem;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 6px #00ff26, inset 0 0 6px #00ff26;
  }

  50% {
    box-shadow: 0 0 20px #00ff26, inset 0 0 14px #00ff26;
  }

  100% {
    box-shadow: 0 0 8px #00ff26, inset 0 0 8px #00ff26;
  }
}

.StartArrow .Border {
  background-color: transparent;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 0 0 8px #00ff26, inset 0 0 8px #00ff26;
  animation: pulse 2s linear 1s infinite;
  position: absolute;
  z-index: 1;
}

.StartArrow div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}