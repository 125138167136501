.HeaderWrapper {
    position: relative;
    z-index: 5;
}

.Header {
    background-color: rgba(85, 85, 85, 0.358);
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.Header div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 15vw;
}

.Header .Link {
    text-decoration: none;
    -webkit-user-drag: none;
}

.Header div h1 {
    color: white;
    font-size: 1rem;
}

.Header h1 {
    color: white;
    font-size: 50px;
}

.Header .NavLink:hover {
    cursor: pointer;
    h1 {
        color: rgb(113, 167, 228);
    }
}