.Projects {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, #ff2d2d, #61caff);
}

.Projects .Bottom {
  width: 100%;
  padding-bottom: 1rem;
  height: fit-content;
  position: relative;
  bottom: 0;
  align-self: flex-end;

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Projects .Header {
  width: 100%;
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Projects .MainDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  gap: 1rem;
  font-size: 1.25rem;
}

.Projects .MainDiv p{
  margin-top: 0.5rem;
}